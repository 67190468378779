import './index.css'

const FooterBar = () => {
  return (
    <div className='FooterBar-Container'>
      <div className='FooterBar-Content-Container'>
        <div className='FooterBar-Contact' id='Description'>evvebarker@gmail.com</div>
        <div className='FooterBar-End' id='Topic-In-Detail'>© Eve Barker 2021</div>
      </div>
    </div>
  )
}

export default FooterBar