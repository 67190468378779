import './index.css'

import pic1 from '../../assets/snp/01.png'
import pic2 from '../../assets/snp/graphic-24.png'
import pic3 from '../../assets/snp/graphic-03.png'
import pic4_1 from '../../assets/snp/graphic-04 (1).png'
import pic4_2 from '../../assets/snp/graphic-05.png'
import pic4_3 from '../../assets/snp/graphic-06.png'
import pic5 from '../../assets/snp/graphic-07.png'
import pic6 from '../../assets/snp/graphic-02.png'
import pic7 from '../../assets/snp/graphic-10.png'
import pic8 from '../../assets/snp/IMG_1269.JPG'
import pic9 from '../../assets/snp/graphic-08.png'
import pic10 from '../../assets/snp/graphic-09.png'
import pic11 from '../../assets/snp/map-01.png'
import pic12 from '../../assets/snp/graphic-01.png'
import pic13_1 from '../../assets/snp/graphic-11.png'
import pic13_2 from '../../assets/snp/graphic-12.png'
import pic14 from '../../assets/snp/graphic-13.png'
import pic15 from '../../assets/snp/graphic-14.png'
import pic16 from '../../assets/snp/graphic-15.png'
import pic17 from '../../assets/snp/graphic-16.png'
import pic18 from '../../assets/snp/graphic-22.png'
import pic19 from '../../assets/snp/graphic-18.png'
import pic20 from '../../assets/snp/graphic-17.png'
import pic21 from '../../assets/snp/19.png'
import pic22 from '../../assets/snp/graphic-23.png'
import pic23 from '../../assets/snp/graphic-21.png'

import Carousel from '../../default_components/carousel'


const SNP = () => {
  return (
    <div className='Project-Information-Container'>
      <div id='Overview'>
        <div className='Project-Single-Content-Container'>
          <div className='Project-Image-Container'>
            <img id='Project-Overview-Image1' src={pic1} width={'100%'} />
          </div>
        </div>
        <div id='Project-Topic-And-Detail'>
          <div className='Project-Topic-Container' id='Sub-Topic'>Story</div>
          <div className='Project-Detail-Container' id='Description'>S&P wanted to create a restaurant that upgrades its brand positioning from a comfort budget restaurant to something
higher. Conceptualize as a lab, SNP Headquarter was born, yet it didn’t deliver a promising future to both
customers and the organization.<br />
My roles for this project are research and strategist planning done under IDS, Thailand.</div>
        </div>
        <div className='Project-Topic-Container' id='Sub-Topic'>Goal</div>
        <div className='Project-Detail-Container' id='Description'>Create more footfall, sales, and create a meaningful customer experience.</div>
      </div>


      <div id='Situation-Assessment'>
        <div className='Project-Sector-Name-Container' id='Sub-Topic'>Situation Assessment</div>
        <div id='Project-Topic-And-Detail'>
          <div className='Project-Detail-Container' id='Description'>Physical store and products</div>
        </div>
        <div className='Project-Many-Content-Container'>
          <div className='Many-Item-Container'>
            <div className='Project-Image-Container'>
              <img id='Project-Situation-Assessment-Image1' src={pic2} width={'100%'} />
            </div>
          </div>
          <div className='Many-Item-Container'>
            <div className='Project-Detail-Container' id='Description' style={{paddingLeft: '15px'}}>
              <b>2019</b><br /><br />
              SNP HQ, a headquarter cafe/restaurant of 
  a famous chain restaurant S&P,
  offers a wide range of foods and beverages, 
  locating in the prime location of Bangkok,
  decorating in a stylish interior, in other 
  words have everything a good cafe has 
  to offer attracts only a few customers that it 
  barely sustain. Why
            </div>
          </div>
        </div>
        <div className='Project-Many-Content-Container'>
          <div className='Many-Item-Container'>
            <div className='Project-Image-Container'>
              <img id='Project-Situation-Assessment-Image1' src={pic3} width={'100%'} />
            </div>
          </div>
          <div className='Many-Item-Container'>
            <div className='Project-Detail-Container' id='Description' style={{paddingLeft: '15px'}}>S&P’s target group cannot distinguish the 
  SNP Headquarter’s identity/food from other 
  brands which lead to bad perception and 
  repel the customers from the cafe
  (all the right pics are SNP’s product comparing
  with similar famous product in the market)
            </div>
          </div>
        </div>
        <div className='Project-Detail-Container' id='Description'>Consequentially, SNP HQ needs to revise their brand DNA to change the perception of their target groups and 
create meaningful experience to build credibility in the brand.
        </div>
      </div>
        

      <div id='Research'>
        <div className='Project-Sector-Name-Container' id='Sub-Topic'>Research </div>
        <div id='Project-Topic-And-Detail'>
          <div className='Project-Topic-Container' id='Sub-Topic'>Shifting perceptions</div>
          <div className='Project-Detail-Container' id='Description'>I interviewed 25 candidates in different age groups (10 loyal customers + 15 aimed customers) to find out their 
perspective toward SNP.
          </div>
        </div>
        <div className='Project-Many-Content-Container'>
          <Carousel items={[
              { path: pic4_1 ,name: 'Past' },
              { path: pic4_2, name: 'Present' },
              { path: pic4_3 ,name: 'Future' }
            ]} />
        </div>
        <div id='Project-Topic-And-Detail'>
          <div className='Project-Detail-Container' id='Description'>The result was that altering the Millennials’ perception is essential since they will control social media in 
the future and with good brand perception, the restaurant can enjoy the benefit passed down from parents 
to the next generation.
          </div>
        </div>
        <div id='Project-Topic-And-Detail'>
          <div className='Project-Topic-Container' id='Sub-Topic'>Target group</div>
        </div>
        <div className='Project-Many-Content-Container'>
          <div className='Project-Image-Container'>
            <img id='Project-Situation-Assessment-Image1' src={pic5} width={'100%'} />
          </div>
        </div>
        <div id='Project-Topic-And-Detail'>
          <div className='Project-Detail-Container' id='Description'>S&P began its journey in 1973 when the restaurant scene wasn’t as big and as innovative as in the present.<br />
Contrasting with the Instagram era we now live in, people used to have little expectations with dining out experience.
One timeless factor is that both users are all concerned about the hospitality services they will receive from the cafe.
          </div>
        </div>
        <div id='Project-Topic-And-Detail'>
          <div className='Project-Topic-Container' id='Sub-Topic'>Insights</div>
          <div className='Project-Detail-Container' id='Description'>I ask users about what they think when they think of SNP</div>
        </div>
        <div className='Project-Many-Content-Container'>
          <div className='Project-Image-Container'>
            <img id='Project-Situation-Assessment-Image1' src={pic6} width={'100%'} />
          </div>
        </div>
        <div id='Project-Topic-And-Detail'>
          <div className='Project-Detail-Container' id='Description'>S&P is known for their homey, classic desserts yet there are no signs of any of products appeal in their 
headquarter branch
          </div>
        </div>
        <div id='Project-Topic-And-Detail'>
          <div className='Project-Topic-Container' id='Sub-Topic'>Preference</div>
          <div className='Project-Detail-Container' id='Description'>Users shared a common combination a good cafe and restaurant has</div>
        </div>
        <div className='Project-Many-Content-Container'>
          <div className='Project-Image-Container'>
            <img id='Project-Situation-Assessment-Image1' src={pic7} width={'100%'} />
          </div>
        </div>
        <div id='Project-Topic-And-Detail'>
          <div className='Project-Topic-Container' id='Sub-Topic'>Competitors</div>
        </div>
        <div className='Project-Many-Content-Container'>
          <div className='Many-Item-Container'>
            <div className='Project-Image-Container'>
              <img id='Project-Situation-Assessment-Image1' src={pic8} width={'100%'} />
            </div>
          </div>
          <div className='Many-Item-Container'>
            <div className='Project-Detail-Container' id='Description' style={{paddingLeft: '15px'}}>I decided to dissect competitor in three sections cross-checking 
  with users concerns<br />
  1. Decoration<br />
  2. Product and Service<br />
  3. Location
            </div>
          </div>
        </div>
        <div id='Project-Topic-And-Detail'>
          <div className='Project-Topic-Container' id='Sub-Topic'>Decoration</div>
          <div className='Project-Detail-Container' id='Description'>Restaurant with industrial interior decoration style, using the same colour schemes with SNP Headquarter.</div>
        </div>
        <div className='Project-Many-Content-Container'>
          <div className='Project-Image-Container'>
            <img id='Project-Situation-Assessment-Image1' src={pic9} width={'100%'} />
          </div>
        </div>
        <div id='Project-Topic-And-Detail'>
          <div className='Project-Topic-Container' id='Sub-Topic'>Product and Service</div>
          <div className='Project-Detail-Container' id='Description'>Restaurants that offer western cuisine with Asian twists with similar signature menus to SNP Headquarter.</div>
        </div>
        <div className='Project-Many-Content-Container'>
          <div className='Project-Image-Container'>
            <img id='Project-Situation-Assessment-Image1' src={pic10} width={'100%'} />
          </div>
        </div>
        <div id='Project-Topic-And-Detail'>
          <div className='Project-Topic-Container' id='Sub-Topic'>Location</div>
        </div>
        <div className='Project-Many-Content-Container'>
          <div className='Many-Item-Container'>
            <div className='Project-Image-Container'>
              <img id='Project-Situation-Assessment-Image1' src={pic11} width={'100%'} />
            </div>
          </div>
          <div className='Many-Item-Container'>
            <div className='Project-Detail-Container' id='Description' style={{paddingLeft: '15px'}}>
              There are plenty of casual dinning options to choose from<br /> 
              Drink : 4 Places<br />
              Drink and dessert : 8 places<br />
              Drink, dessert and food : 23 places<br />
            </div>
          </div>
        </div>
        <div id='Project-Topic-And-Detail'>
          <div className='Project-Topic-Container' id='Sub-Topic'>Customers’ perception</div>
          <div className='Project-Detail-Container' id='Description'>The source of the two axes is based on the analysis of the restaurant expectations of the current customers</div>
        </div>
        <div className='Project-Many-Content-Container'>
          <div className='Project-Image-Container'>
            <img id='Project-Situation-Assessment-Image1' src={pic12} width={'100%'} />
          </div>
        </div>
      </div>
      

      <div id='Strategy'>
        <div className='Project-Sector-Name-Container' id='Sub-Topic'>Strategy</div>
        <div className='Project-Many-Content-Container'>
          <Carousel items={[
              { path: pic13_1 ,name: 'Before' },
              { path: pic13_2, name: 'After' }
            ]} />
        </div>
        <div id='Project-Topic-And-Detail'>
          <div className='Project-Topic-Container' id='Sub-Topic'>Ideation</div>
        </div>
        <div className='Project-Many-Content-Container'>
          <div className='Many-Item-Container'>
            <div className='Project-Image-Container'>
              <img id='Project-Situation-Assessment-Image1' src={pic14} width={'100%'} />
            </div>
          </div>
          <div className='Many-Item-Container'>
            <div className='Project-Detail-Container' id='Description' style={{paddingLeft: '15px'}}>
              Text-cloud<br /><br />
              The distillation to find the core values that will meet the needs of the SNP HQ’s customers
            </div>
          </div>
        </div>
        <div id='Project-Topic-And-Detail'>
          <div className='Project-Detail-Container' id='Description'>As delicious food alone can no longer satisfy the needs of the young adults but 
it is the perfect blend of excellent experience, undivided attention and distinctive brand identity they yearn for. 
Hence, through innovation and longstanding authenticity, SNP HQ can create an outstanding, tailor-made experience 
for every customer.</div>
        </div>
        <div className='Project-Many-Content-Container'>
          <div className='Project-Image-Container'>
            <img id='Project-Situation-Assessment-Image1' src={pic15} width={'100%'} />
          </div>
        </div>
        <div id='Project-Topic-And-Detail'>
          <div className='Project-Topic-Container' id='Sub-Topic'>Customer Journey</div>
          <div className='Project-Detail-Container' id='Description'>I created customer journeys to identify new opportunities and strategies in designing the SNP HQ. 
This is the summarize version of them.
          </div>
        </div>
        <div className='Project-Many-Content-Container'>
          <div className='Project-Image-Container'>
            <img id='Project-Situation-Assessment-Image1' src={pic16} width={'100%'} />
          </div>
        </div>
        <div id='Project-Topic-And-Detail'>
          <div className='Project-Topic-Container' id='Sub-Topic'>Action Plan</div>
          <div className='Project-Detail-Container' id='Description'>To help the client understand the whole picture of what needs to be done and prioritize the tasks to gain the most with 
limited resources.
          </div>
        </div>
        <div className='Project-Many-Content-Container'>
          <div className='Project-Image-Container'>
            <img id='Project-Situation-Assessment-Image1' src={pic17} width={'100%'} />
          </div>
        </div>
        <div id='Project-Topic-And-Detail'>
          <div className='Project-Topic-Container' id='Sub-Topic'>Scenarios</div>
        </div>
        <div className='Project-Many-Content-Container'>
          <div className='Project-Image-Container'>
            <img id='Project-Situation-Assessment-Image1' src={pic18} width={'100%'} />
          </div>
        </div>
        <div className='Project-Many-Content-Container'>
          <div className='Project-Image-Container'>
            <img id='Project-Situation-Assessment-Image1' src={pic19} width={'100%'} />
          </div>
        </div>
      </div>

      <div id='End1'>
        <div className='Project-Many-Content-Container'>
          <div className='Project-Image-Container'>
            <img id='Project-Situation-Assessment-Image1' src={pic20} width={'100%'} />
          </div>
        </div>
        <div className='Project-Many-Content-Container'>
          <div className='Project-Image-Container'>
            <img id='Project-Situation-Assessment-Image1' src={pic21} width={'100%'} />
          </div>
        </div>
      </div>

      <div id='End2'>
        <div className='Project-Many-Content-Container'>
          <div className='Project-Image-Container'>
            <img id='Project-Situation-Assessment-Image1' src={pic22} width={'100%'} />
          </div>
        </div>
        <div className='Project-Many-Content-Container'>
          <div className='Project-Image-Container'>
            <img id='Project-Situation-Assessment-Image1' src={pic23} width={'100%'} />
          </div>
        </div>
        <div className='Project-Detail-Container' id='Description'>The storyboard was illustrated by Supphakan Phetnoi.</div>
      </div>
    </div>
  )
}

export default SNP