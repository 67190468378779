import './index.css'

import pic1 from '../../assets/naiyc/Scan 309.jpeg'
import pic2 from '../../assets/naiyc/Untitled-1-02.png'
import pic3 from '../../assets/naiyc/existing.png'
import pic4 from '../../assets/naiyc/logo-01.png'
import pic5 from '../../assets/naiyc/Untitled-1-01.png'
import pic6 from '../../assets/naiyc/ad.png'
import pic7 from '../../assets/naiyc/limited.png'
import pic8 from '../../assets/naiyc/edition.png'
import pic9 from '../../assets/naiyc/fund.png'
import pic10 from '../../assets/naiyc/recycle.png'
import pic11 from '../../assets/naiyc/naiycgirl.png'
import pic12 from '../../assets/naiyc/collab.png'
import pic13 from '../../assets/naiyc/Untitled-1-03.png'
import pic14 from '../../assets/naiyc/desktopinterface.png'
import pic15 from '../../assets/naiyc/mobile.png'
import pic16 from '../../assets/naiyc/element.png'
import pic17 from '../../assets/naiyc/Artboard – 2.png'
import pic18 from '../../assets/naiyc/Artboard – 1.png'

const NAIYC = () => {
  return (
    <div className='Project-Information-Container'>
      <div id='Overview'>
        <div className='Project-Single-Content-Container'>
          <div className='Project-Image-Container'>
            <img id='Project-Overview-Image1' src={pic1} width={'100%'}/>
          </div>
        </div>
        <div id='Project-Topic-And-Detail'>
          <div className='Project-Topic-Container' id='Sub-Topic'>Story</div>
          <div className='Project-Detail-Container' id='Description'>Born on my bedroom floor, Not Available In Yr Country (NAIYC) is a bootstrap project of mine creates to build 
a mindful community through selling unique clothing that isn’t available in my country. A part of the profit is intended to 
support small non-profit organizations or people in need of the money hoping to improve people’s lives in small steps.
( learn more: https://www.instagram.com/notavailableinyrcountry/?igshid=12xlv0eo35i )</div>
        </div>
        <div className='Project-Topic-Container' id='Sub-Topic'>Goal</div>
        <div className='Project-Detail-Container' id='Description'>
          Supporting Locals while building a beautiful and transparent brand community.<br />
          Creating a luxury experience for customers at an affordable price point.
        </div>
      </div>


      <div id='Brand'>
        <div className='Project-Sector-Name-Container' id='Sub-Topic'>Brand</div>
        <div className='Project-Topic-Container'id='Sub-Topic'>Concept</div>
        <div className='Project-Many-Content-Container'>
          <div className='Project-Image-Container'>
            <img id='Project-Research-Image4' src={pic2} width={'100%'} />
          </div>
        </div>
        <div id='Project-Topic-And-Detail'>
          <div className='Project-Detail-Container' id='Description'>NAIYC is where I turned my childhood hobbies and my mom’s embroidery skills into a business.</div>
        </div>
        <div className='Project-Topic-Container' id='Sub-Topic'>Existing Brands</div>
        <div className='Project-Many-Content-Container'>
          <div className='Project-Image-Container'>
            <img id='Project-Research-Image4' src={pic3} width={'100%'} />
          </div>
        </div>
        <div id='Project-Topic-And-Detail'>
          <div className='Project-Detail-Container' id='Description'>At the point when I started, most Thai fashion brands are giving the same catalog vibe that I am not a fan of. 
No one is doing hand-craft products at an affordable price point. Everything was mass production and
computerized.
          </div>
        </div>
        <div id='Project-Topic-And-Detail'>
          <div className='Project-Detail-Container' id='Description'>To distinct my brand from others, I decided that all product and element will be made by hand and 
produce in limited stock.
          </div>
        </div>
        <div className='Project-Topic-Container' id='Sub-Topic'>Identity</div>
        <div className='Project-Many-Content-Container'>
          <div className='Many-Item-Container'>
            <div className='Project-Image-Container'>
              <img id='Project-Testing-Image1' src={pic4} width={'100%'} />
            </div>
          </div>
          <div className='Many-Item-Container'>
            <div className='Project-Detail-Container' id='Description' style={{paddingLeft: '15px'}}>I initially created a logo organically from my handwriting
  and later develop variations of it using hand-embroidery
  techniques that we use in our products.
            </div>
          </div>
        </div>
        <div className='Project-Topic-Container' id='Sub-Topic'>Tone of Voice</div>
        <div className='Project-Many-Content-Container'>
          <div className='Many-Item-Container'>
            <div className='Project-Image-Container'>
              <img id='Project-Testing-Image1' src={pic5} width={'100%'} />
            </div>
          </div>
          <div className='Many-Item-Container'>
            <div className='Project-Detail-Container' id='Description' style={{paddingLeft: '15px'}}>Celebrate living free and being real. 
Tongue in cheek, playful and ironic
wordings are used to contrast my naive child-like 
illustration.
            </div>
          </div>
        </div>
        <div id='Project-Topic-And-Detail'>
          <div className='Project-Topic-Container' id='Sub-Topic'>Art direction</div>
          <div className='Project-Detail-Container' id='Description'>Free-spirited and experimental</div>
        </div>
        <div className='Project-Many-Content-Container'>
          <div className='Project-Image-Container'>
            <img id='Project-Testing-Image1' src={pic6} width={'100%'} />
          </div>
        </div>
        <div id='Project-Topic-And-Detail'>
          <div className='Project-Detail-Container' id='Description'>To break the traditional fashion brand photoshoot, I edit the photograph using various methods including paint, 
hand-embroidery, collage to reflect the playful and craftiness of the brand. Most of the models are selected from 
real customers.
          </div>
        </div>
        <div id='Project-Topic-And-Detail'>
          <div className='Project-Topic-Container' id='Sub-Topic'>Product line</div>
          <div className='Project-Detail-Container' id='Description'>
            There are two main categories which are limited pieces and edition. All items are illustrated by me.<br /><br />
            1.) Limited pieces:  One of a kind hand-embroidered pieces which 95% are made from vintage garments, 
another 5% are from deadstock fabric.
          </div>
        </div>
        <div className='Project-Many-Content-Container'>
          <div className='Project-Image-Container'>
            <img id='Project-Testing-Image1' src={pic7} width={'100%'} />
          </div>
        </div>
        <div id='Project-Topic-And-Detail'>
          <div className='Project-Detail-Container' id='Description'>
          2.) Edition: limited screen clothing, partial of the profit goes to NGO. (NO RESTOCK. PERIOD.)
          </div>
        </div>
        <div className='Project-Many-Content-Container'>
          <div className='Project-Image-Container'>
            <img id='Project-Testing-Image1' src={pic8} width={'100%'} />
          </div>
        </div>
        <div id='Project-Topic-And-Detail'>
          <div className='Project-Topic-Container' id='Sub-Topic'>Giving back to community</div>
          <div className='Project-Detail-Container' id='Description'>
          I chose to fund non-profit organizations that are super small and local as it needs financial support more.<br /> 
          I also asked the community to brainstorm where should NAIYC give back next. 
          </div>
        </div>
        <div className='Project-Many-Content-Container'>
          <div className='Project-Image-Container'>
            <img id='Project-Testing-Image1' src={pic9} width={'100%'} />
          </div>
        </div>
        <div id='Project-Topic-And-Detail'>
          <div className='Project-Detail-Container' id='Description'>
          Apart from financial funding, I tried to create a movement of reuse packaging and also rewearing our product to 
stop overconsumption as well. 
          </div>
        </div>
        <div className='Project-Many-Content-Container'>
          <div className='Project-Image-Container'>
            <img id='Project-Testing-Image1' src={pic10} width={'100%'} />
          </div>
        </div>
        <div id='Project-Topic-And-Detail'>
          <div className='Project-Topic-Container' id='Sub-Topic'>Customer Engagement</div>
        </div>
        <div id='Project-Topic-And-Detail'>
          <div className='Project-Topic-Container' id='Sub-Topic'>#notavailablegirl</div>
          <div className='Project-Detail-Container' id='Description'>
          We are an advertisement-free brand so everything that’s happening 100% happening because of our customers’ 
word of mouth and engagement. Almost every one of them repurchasing the products again and again and 
eventually becoming my friends IRL. 
          </div>
        </div>
        <div className='Project-Many-Content-Container'>
          <div className='Project-Image-Container'>
            <img id='Project-Testing-Image1' src={pic11} width={'100%'} />
          </div>
        </div>
        <div id='Project-Topic-And-Detail'>
          <div className='Project-Topic-Container' id='Sub-Topic'>Content engagement</div>
        </div>
        <div className='Project-Many-Content-Container'>
          <div className='Project-Image-Container'>
            <img id='Project-Testing-Image1' src={pic12} width={'100%'} />
          </div>
        </div>
        <div className='Project-Detail-Container' id='Description'>Apart from taking photos and review the products, #notavailablegirl are also producing contents and illustrations 
in collaboration with the brand.  (These are the artworks in collaboration with Charmae, Walairat, Beaut, Punpun,
Pim, On, and Meuw.)
        </div>
      </div>


      <div id='Channel'>
        <div className='Project-Sector-Name-Container' id='Sub-Topic'>Channel</div>
        <div id='Project-Topic-And-Detail'>
          <div className='Project-Detail-Container' id='Description'>I launch the brand on Instagram back in 2018 just to test the water, the platform was suitable for the brand and budget 
I had at that point but once the brand started to obtain international customers whose nature are different from 
Thai customers (my initial customers), I found out that they are not comfortable purchasing via Instagram so I decided to 
create a proper website.
          </div>
        </div>
        <div id='Project-Topic-And-Detail'>
          <div className='Project-Topic-Container' id='Sub-Topic'>Early Sketch</div>
        </div>
        <div className='Project-Many-Content-Container'>
          <div className='Project-Image-Container'>
            <img id='Project-Testing-Image1' src={pic13} width={'100%'} />
          </div>
        </div>
        <div id='Project-Topic-And-Detail'>
          <div className='Project-Topic-Container' id='Sub-Topic'>Desktop Interface</div>
          <div className='Project-Detail-Container' id='Description'>The final version has created after several testings with my real customers. Here are some pages of the website.</div>
        </div>
        <div className='Project-Many-Content-Container'>
          <div className='Project-Image-Container'>
            <img id='Project-Testing-Image1' src={pic14} width={'100%'} />
          </div>
        </div>
        <div id='Project-Topic-And-Detail'>
          <div className='Project-Topic-Container' id='Sub-Topic'>Mobile Interface</div>
        </div>
        <div className='Project-Many-Content-Container'>
          <div className='Project-Image-Container'>
            <img id='Project-Testing-Image1' src={pic15} width={'100%'} />
          </div>
        </div>
        <div id='Project-Topic-And-Detail'>
          <div className='Project-Topic-Container' id='Sub-Topic'>Elements</div>
          <div className='Project-Detail-Container' id='Description'>My mom hand-embroidered these icons for the final version of the website.</div>
        </div>
        <div className='Project-Many-Content-Container'>
          <div className='Project-Image-Container'>
            <img id='Project-Testing-Image1' src={pic16} width={'100%'} />
          </div>
        </div>
        <div id='Project-Topic-And-Detail'>
          <div className='Project-Topic-Container' id='Sub-Topic'>Display System</div>
        </div>
        <div id='Project-Topic-And-Detail'>
          <div className='Project-Topic-Container' id='Sub-Topic'>Exclusive content/ Early access</div>
          <div className='Project-Detail-Container' id='Description'>Despite the growing demand, NAIYC is a family-owned-and-operated business ( me, mom, and my aunt sometimes ) 
and we want to keep it that way. I decided that the privilege of purchasing the limited product will go-to member of 
NAIYC only.
          </div>
        </div>
        <div className='Project-Many-Content-Container'>
          <div className='Project-Image-Container'>
            <img id='Project-Testing-Image1' src={pic17} width={'100%'} />
          </div>
        </div>
        <div id='Project-Topic-And-Detail'>
          <div className='Project-Topic-Container' id='Sub-Topic'>Availability</div>
          <div className='Project-Detail-Container' id='Description'>I use b/w pictures to differentiate items that are out of stock from the available items.</div>
        </div>
        <div className='Project-Many-Content-Container'>
          <div className='Project-Image-Container'>
            <img id='Project-Testing-Image1' src={pic18} width={'100%'} />
          </div>
        </div>
        <div className='Project-Detail-Container' id='Description'>The website is under construction, will be launch around late 2021 to early 2022</div>
      </div>
    </div>
  )
}

export default NAIYC