import './index.css'

import pic1 from '../../assets/outline/1.jpg'
import pic2 from '../../assets/outline/users-01.png'
import pic3 from '../../assets/outline/Untitled-2-01.png'
import pic4 from '../../assets/outline/1.png'
import pic5 from '../../assets/outline/technique.png'
import pic6 from '../../assets/outline/users-02.png'
import pic7 from '../../assets/outline/match.png'
import pic8 from '../../assets/outline/visualcue.png'
import pic9 from '../../assets/outline/prototype1.png'
import pic10 from '../../assets/outline/testing (1).png'
import pic11 from '../../assets/outline/Untitled-2-02.png'
import pic12 from '../../assets/outline/cue.jpg'
import pic13 from '../../assets/outline/playbook.png'
import pic14 from '../../assets/outline/content.png'
import pic15 from '../../assets/outline/sample.png'

// link box
// detail box
// topic box
// picture box => picture, image sources
// many content container => picture?, detail?
// single content container => picture?, detail?

const OUTLINE = () => {
  return (
    <div className='Project-Information-Container'>
      <div id='Overview'>
        <div className='Project-Single-Content-Container'>
          <div className='Project-Image-Container'>
            <img id='Project-Overview-Image1' src={pic1} width={'100%'}/>
          </div>
        </div>
        <div id='Project-Topic-And-Detail'>
          <div className='Project-Topic-Container' id='Sub-Topic'>Story</div>
          <div className='Project-Detail-Container' id='Description'>Out-line is a school project based on an assignment to design a toy for kids. At that point of the time, 
I was very close with my young cousin and I spotted that he didn’t have a chance to do much of free drawings 
and when he had a chance, he wasn’t confident enough to do it so I started to observe his peers and kids 
in my neighbourhood and found out that many of them are facing this problem.</div>
        </div>
        <div className='Project-Topic-Container' id='Sub-Topic'>Goal</div>
        <div className='Project-Detail-Container' id='Description'>Designing a tool to help children experiment and break free from drawing with nonexistent borders.</div>
      </div>


      <div id='Research'>
        <div className='Project-Sector-Name-Container' id='Sub-Topic'>Research</div>
        <div className='Project-Topic-Container' id='Sub-Topic'>Target Users</div>
        <div className='Project-Many-Content-Container'>
          <div className='Project-Image-Container'>
            <img id='Project-Research-Image1' src={pic2} width={'100%'} />
          </div>
        </div>
        <div className='Project-Topic-Container' id='Sub-Topic'>Contributing Factors</div>
        <div className='Project-Many-Content-Container'>
          <div className='Project-Image-Container'>
            <img id='Project-Research-Image4' src={pic3} width={'100%'} />
          </div>
        </div>
        <div id='Project-Topic-And-Detail'>
          <div className='Project-Detail-Container' id='Description'>Although imaginary outlines are helping kids understand shapes and forms, preventing kids from being experimental. 
It is self-evident that these lines don’t exist in the real world so why should we focus so much on it?</div>
        </div>
        <div className='Project-Single-Content-Container'>
          <div className='Project-Image-Container'>
            <img id='Project-Research-Image5' src={pic4} width={'100%'} />
          </div>
        </div>
        <div className='Project-Detail-Container' id='Description'>A preliminary experiment was conducted to test out how kids react when asked to draw a bird for three 
consecutive days without giving any templates, guides, or outlines and the result was that children were 
being inventive when given opportunities to draw freely.
        </div>
      </div>


      <div id='Ideation'>
        <div className='Project-Sector-Name-Container' id='Sub-Topic'>Ideation</div>
        <div id='Project-Topic-And-Detail'>
          <div className='Project-Detail-Container' id='Description'>According to research, children at these ages are into something mysterious and magical.</div>
        </div>
        <div id='Project-Topic-And-Detail'>
          <div className='Project-Topic-Container' id='Sub-Topic'>Direction</div>
          <div className='Project-Detail-Container' id='Description'>I was experimenting with alternative ways to create forms without using any outlines and came across 
  age-appropriate watercolor techniques which are not only magical but give a unique experience for 
  the target group.</div>
        </div>
        <div className='Project-Many-Content-Container'>
          <div className='Project-Image-Container'>
            <img id='Project-Ideation-Image1' src={pic5} width={'100%'} />
          </div>
        </div>
        <div id='Project-Topic-And-Detail'>
          <div className='Project-Topic-Container' id='Sub-Topic'>Theme</div>
          <div className='Project-Detail-Container' id='Description'>The playbook was initially instructing about observing and drawing nature as it is easy for kids to imagine something 
  they are already aware of. I was also observing whether children can finish tasks by themselves or should the coloring 
  playbook offers visual guidelines.</div>
        </div>
        <div className='Project-Many-Content-Container'>
          <div className='Project-Image-Container'>
            <img id='Project-Ideation-Image3' src={pic6} width={'100%'} />
          </div>
        </div>
        <div className='Project-Detail-Container' id='Description'>The result was that task-oriented playbook is not the most appropriate way to grab and sustain their attention and 
sometimes wordings are misleading. Thus, the adventure storyline which is the most popular choice of book to 
the children according to surveys was incorporated into the playbook to maintain children’s level of engagement.
        </div>
      </div>


      <div id='Design'>
        <div className='Project-Sector-Name-Container' id='Sub-Topic'>Design Development</div>
        <div id='Project-Topic-And-Detail'>
          <div className='Project-Detail-Container' id='Description'>I was mapping appropriate watercolor techniques with the storyline to make learning to create forms without outlines 
process more effortless.</div>
        </div>
        <div className='Project-Image-Container'>
          <img id='Project-Design-Image1' src={pic7} width={'100%'} />
        </div>
        <div className='Project-Topic-Container' id='Sub-Topic'>Format</div>
        <div className='Project-Many-Content-Container'>
          <div className='Project-Detail-Container' id='Description'>After consulting with my lecturers, booklet is the 
best way as it gives children a sense of continuation.

I also design visual cues to help explain watercolour 
techniques and guide children through out the story.
          </div>
        </div>
        <div className='Project-Many-Content-Container'>
          <div className='Project-Image-Container'>
            <img id='Project-Design-Image5' src={pic8} width={'100%'} />
          </div>
        </div>
        <div className='Project-Topic-Container' id='Sub-Topic'>Prototype1</div>
        <div className='Project-Many-Content-Container'>
          <div className='Project-Image-Container'>
            <img id='Project-Design-Image7' src={pic9} width={'100%'} />
          </div>
        </div>
      </div>


      <div id='Testing'>
        <div className='Project-Sector-Name-Container' id='Sub-Topic'>Testing</div>
        <div id='Project-Topic-And-Detail'>
          <div className='Project-Detail-Container' id='Description'>Testing was conducted at Asokwit on Nut School with 10 students from primary school year one and two.</div>
        </div>
        <div className='Project-Many-Content-Container'>
          <div className='Project-Image-Container'>
            <img id='Project-Testing-Image1' src={pic10} width={'100%'} />
          </div>
        </div>
        <div className='Project-Image-Container'>
          <img id='Project-Testing-Image4' src={pic11} width={'100%'} />
        </div>
        <div className='Project-Many-Content-Container'>
          <div className='Many-Item-Container'>
            <div className='Project-Image-Container'>
              <img id='Project-Evaluation-Image1' src={pic12} width={'100%'} />
            </div>
          </div>
          <div className='Many-Item-Container'>
            <div className='Project-Detail-Container' id='Description' style={{paddingLeft: '15px'}}>
            According to the testing result, I made the new set of visual cues in black and white and try to play around with typography even more.
            </div>
          </div>
        </div>
        <div id='Project-Topic-And-Detail'>
          <div className='Project-Topic-Container' id='Sub-Topic'>Prototype2</div>
          <div className='Project-Detail-Container' id='Description'>
          To give children the freedom to explore color palettes without any guidelines, the playbook is finalized in black 
and white color schemes.
          </div>
        </div>
        <div className='Project-Many-Content-Container'>
          <div className='Project-Image-Container'>
            <img id='Project-Testing-Image1' src={pic13} width={'100%'} />
          </div>
        </div>
        <div className='Project-Topic-Container' id='Sub-Topic'>Content</div>
        <div className='Project-Many-Content-Container'>
          <div className='Project-Image-Container'>
            <img id='Project-Testing-Image1' src={pic14} width={'100%'} />
          </div>
        </div>
        <div className='Project-Topic-Container' id='Sub-Topic'>Sample</div>
        <div className='Project-Many-Content-Container'>
          <div className='Project-Image-Container'>
            <img id='Project-Testing-Image1' src={pic15} width={'100%'} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default OUTLINE