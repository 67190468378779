import './index.css'
import { A } from 'hookrouter'
import { useEffect, useState } from 'react'

const HomePageHeaderBar = () => {
  const defaultLinkStyle = {
    color: "#767676",
    "text-decoration": "none"
  }
  const focusLinkStyle = {
    color: "#00c7d8",
    "text-decoration": "none"
  }
  const [projectLinkStyle, setProjectLinkStyle] = useState(defaultLinkStyle)
  const [everythingLinkStyle, setEverythingLinkStyle] = useState(defaultLinkStyle)

  useEffect(() => {
    const path = window.location.pathname

    if (path == '/' || path == '/project') {
      setProjectLinkStyle(focusLinkStyle)
      setEverythingLinkStyle(defaultLinkStyle)
    } else {
      setProjectLinkStyle(defaultLinkStyle)
      setEverythingLinkStyle(focusLinkStyle)
    }
  })

  return (
    <div className='HomePage-HeaderBar-Container'>
      <div className='Header-Description-Container'>
        <div className='Header-Description'>
          <div id='Header-Description' id='Topic'>
            Hello! I’m Eve Barker, a designer.
          </div>
        </div>
      </div>
      
      <div className='Header-Menu-Container'>
        <div className='Project-Link-Container'>
          <A href="/project" id='Topic-Description' style={projectLinkStyle}>Selected Projects</A>
        </div>
        <div className='Everything-Link-Container'>
          <A href="/everything" id='Topic-Description' style={everythingLinkStyle}>Everything</A>
        </div>
      </div>
    </div>
  )
}

export default HomePageHeaderBar