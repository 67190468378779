import './index.css'

const SideBarItem = ({ name='', id=''}) => {
  const link_name = `#${id}`

  return (
    <div className='SideaBar-Menu' id='Side-Sub-Topic'>
      {/* TODO: 2 lines */}
      <a href={link_name}>{name}</a>
    </div>
  )
}

const SideBar = ({ menu=[] }) => {
  return (
    <div className='SideBar-Container'>
      {
        menu.map(item => {
          return <SideBarItem name={item.name} id={item.id}/>
        })
      }
    </div>
  )
}

export default SideBar