import pic1 from '../../../assets/talardrod/graphic-05.png'
import pic2 from '../../../assets/talardrod/graphic-10.png'
import pic3 from '../../../assets/talardrod/sell-01.png'
import pic4 from '../../../assets/talardrod/sell-02.png'
import pic5 from '../../../assets/talardrod/sell-03 copy.png'
import pic6 from '../../../assets/talardrod/seller_m.png'

const SellerContent = () => {
  return (
    <div>
      <div id='Project-Topic-And-Detail'>
        <div className='Project-Topic-Container' id='Sub-Topic'>Interview</div>
        <div className='Project-Detail-Container' id='Description'>After conducting the interview, users need the process of car selling to be easier, faster, and more 
trustworthy.
        </div>
      </div>
      <div className='Project-Many-Content-Container'>
        <div className='Project-Image-Container'>
          <img id='Project-Discovery-Image1' src={pic1} width={'100%'} />
        </div>
      </div>
      <div id='Project-Topic-And-Detail'>
        <div className='Project-Topic-Container' id='Sub-Topic'>Journey map</div>
      </div>
      <div className='Project-Many-Content-Container'>
        <div className='Project-Image-Container'>
          <img id='Project-Discovery-Image1' src={pic2} width={'100%'} />
        </div>
      </div>
      <div id='Project-Topic-And-Detail'>
        <div className='Project-Topic-Container' id='Sub-Topic'>Design Development</div>
        <div className='Project-Detail-Container' id='Description'>Partial image of some design development and changes made according to users’ reaction and
feedback during testing.
        </div>
      </div>
      <div className='Project-Many-Content-Container'>
        <div className='Project-Image-Container'>
          <img id='Project-Discovery-Image1' src={pic3} width={'100%'} />
        </div>
      </div>
      <div className='Project-Many-Content-Container'>
        <div className='Project-Image-Container'>
          <img id='Project-Discovery-Image1' src={pic4} width={'100%'} />
        </div>
      </div>
      <div className='Project-Many-Content-Container'>
        <div className='Project-Image-Container'>
          <img id='Project-Discovery-Image1' src={pic5} width={'100%'} />
        </div>
      </div>
      <div id='Project-Topic-And-Detail'>
        <div className='Project-Topic-Container' id='Sub-Topic'>Mobile Version</div>
      </div>
      <div className='Project-Many-Content-Container'>
        <div className='Project-Image-Container'>
          <img id='Project-Discovery-Image1' src={pic6} width={'100%'} />
        </div>
      </div>
    </div>
  )
}

export default SellerContent