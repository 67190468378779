import './index.css'

import pic1 from '../../assets/barter/01.jpg'
import pic2 from '../../assets/barter/02.png'
import pic3 from '../../assets/barter/03.jpg'
import pic4 from '../../assets/barter/n-03.jpg'
import pic5 from '../../assets/barter/5.jpg'
import pic6 from '../../assets/barter/flowresize.JPG'
import pic7 from '../../assets/barter/prototypee.png'
import pic8 from '../../assets/barter/08.png'
import pic9 from '../../assets/barter/cjupdate-05.png'
import pic10 from '../../assets/barter/9.png'
import pic11 from '../../assets/barter/10.png'
import pic12 from '../../assets/barter/11.png'

const BARTER = () => {
  return (
    <div className='Project-Information-Container'>
      <div id='Overview'>
        <div className='Project-Single-Content-Container'>
          <div className='Project-Image-Container'>
          <iframe src="https://player.vimeo.com/video/279971288" width="640" height="360" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
          </div>
        </div>
        <div id='Project-Topic-And-Detail'>
          <div className='Project-Topic-Container' id='Sub-Topic'>Story</div>
          <div className='Project-Detail-Container' id='Description'>Barter is a school project (Timeline: 6weeks) , originally named battery bump, that came exactly from the brief when 
my lecturer asked everyone to comes up with an everyday problem that involves 2 people or more. 
My problem is that my phone always runs out of battery and I know that you do too.</div>
        </div>
        <div className='Project-Topic-Container' id='Sub-Topic'>Goal</div>
        <div className='Project-Detail-Container' id='Description'>Creating a tool to help extended phone battery life when needed.</div>
      </div>


      <div id='Discovery'>
        <div className='Project-Sector-Name-Container' id='Sub-Topic'>Discovery</div>
        <div id='Project-Topic-And-Detail'>
          <div className='Project-Detail-Container' id='Description'>I had a friend whom I always borrow a charger from when my phone battery runs out when I’m at school but what if
my phone dies in unfamiliar places? Where can I go and charge my phone? </div>
        </div>
        <div id='Project-Topic-And-Detail'>
          <div className='Project-Detail-Container' id='Description'>To see the potential of the project, I listed down the problems people with low-battery phones face and create a rough
business model canvas.</div>
        </div>
        <div className='Project-Single-Content-Container'>
          <div className='Project-Image-Container'>
            <img id='Project-Discovery-Image1' src={pic1} width={'65%'} />
          </div>
        </div>
        <div className='Project-Many-Content-Container'>
          <div className='Project-Image-Container'>
            <img id='Project-Discovery-Image2' src={pic2} width={'100%'} />
          </div>
        </div>
        <div className='Project-Detail-Container' id='Description'>The business model confirmed that the project can be executed</div>
      </div>


      <div id='Research'>
        <div className='Project-Sector-Name-Container' id='Sub-Topic'>Research</div>
        <div id='Project-Topic-And-Detail'>
          <div className='Project-Topic-Container' id='Sub-Topic'>Identify Target Users</div>
          <div className='Project-Detail-Container' id='Description'>According to online surveys, two main user groups that have encountered this problem frequently are teenagers and 
office workers (age 18-30), and a significant part of them often switch roles between battery borrower and lender. 
After that, I conducted 20 participants in-depth phone interviews and made personas.
          </div>
        </div>
        <div className='Project-Topic-Container' id='Sub-Topic'>Borrower</div>
        <div className='Project-Many-Content-Container'>
          <div className='Project-Image-Container'>
            <img id='Project-Research-Image1' src={pic3} width={'100%'} />
          </div>
        </div>
        <div className='Project-Topic-Container' id='Sub-Topic'>Lender</div>
        <div className='Project-Many-Content-Container'>
          <div className='Project-Image-Container'>
            <img id='Project-Research-Image2' src={pic4} width={'100%'} />
          </div>
        </div>
      </div>


      <div id='Design'>
        <div className='Project-Sector-Name-Container' id='Sub-Topic'>Design</div>
        <div id='Project-Topic-And-Detail'>
          <div className='Project-Detail-Container' id='Description'>
          Before designing UX/UI, I created a customer journey to break down situations and find out what users’ needs 
in different stages.
          </div>
        </div>
        <div className='Project-Many-Content-Container'>
          <div className='Project-Image-Container'>
            <img id='Project-Design-Image1' src={pic5} width={'100%'} />
          </div>
        </div>
        <div className='Project-Topic-Container' id='Sub-Topic'>Flowchart</div>
        <div className='Project-Single-Content-Container'>
          <div className='Project-Image-Container'>
            <img id='Project-Design-Image2' src={pic6} width={'60%'} />
          </div>
        </div>
        <div id='Project-Topic-And-Detail'>
          <div className='Project-Topic-Container' id='Sub-Topic'>Prototype 1</div>
          <div className='Project-Detail-Container' id='Description'>
          First draft and first mock-up I prepared for the usability testing.
          </div>
        </div>
        <div className='Project-Many-Content-Container'>
          <div className='Project-Image-Container'>
            <img id='Project-Design-Image4' src={pic7} width={'100%'} />
          </div>
        </div>
      </div>


      <div id='Testing'>
        <div className='Project-Sector-Name-Container' id='Sub-Topic'>Testing</div>
        <div className='Project-Many-Content-Container'>
          <div className='Project-Image-Container'>
            <img id='Project-Testing&Evaluation-Image1' src={pic8} width={'100%'} />
          </div>
        </div>
        <div id='Project-Topic-And-Detail'>
          <div className='Project-Detail-Container' id='Description'>Testing was conducted casually on in-depth interviewees and presenting ideas with a potential partner (AIS Thailand) 
to find out about
          </div>
        </div>
        <div className='Project-Many-Content-Container'>
          <div className='Project-Image-Container'>
            <img id='Project-Testing&Evaluation-Image2' src={pic9} width={'100%'} />
          </div>
        </div>
        <div className='Project-Single-Content-Container'>
          <div className='Project-Image-Container'>
            <img id='Project-Testing&Evaluation-Image3' src={pic10} width={'100%'} />
          </div>
        </div>
        <div id='Project-Topic-And-Detail'>
          <div className='Project-Topic-Container' id='Sub-Topic'>Design Development</div>
          <div className='Project-Detail-Container' id='Description'>I changed the art direction, using color schemes that represent battery and also simply routes to make it easier for 
users to navigate around the app and make the graphic less cartoon so that it appeals to more target users.
          </div>
        </div>
        <div className='Project-Single-Content-Container'>
          <div className='Project-Image-Container'>
            <img id='Project-Testing&Evaluation-Image4' src={pic11} width={'100%'} />
          </div>
        </div>
        <div id='Project-Topic-And-Detail'>
          <div className='Project-Topic-Container' id='Sub-Topic'>New Sitemap</div>
        </div>
        <div className='Project-Many-Content-Container'>
          <div className='Project-Image-Container'>
            <img id='Project-Testing&Evaluation-Image6' src={pic12} width={'100%'} />
          </div>
        </div>
        <div id='Project-Topic-And-Detail'>
          <div className='Project-Topic-Container' id='Sub-Topic'>Next Phrase</div>
          <div className='Project-Detail-Container' id='Description'>
            - Develop features for an extension charging service.<br/> 
            - Create guides or games that help lessen the awkwardness between a borrower and lender during charging sessions.<br/>
            - Ease the pain of awkwardness
          </div>
        </div>
      </div>
    </div>
  )
}

export default BARTER