import './index.css';

import pic1_0 from '../../assets/everything/fkm21.gif'
import pic1_1 from '../../assets/everything/logoolic.png'
import pic1_2 from '../../assets/selected_page/songkla_aquarium.png'
import pic1_3 from '../../assets/everything/image_from_ios.jpg'
import pic1_4 from '../../assets/selected_page/SNP.png'

import pic2_1 from '../../assets/selected_page/taladrod.png'
import pic2_2 from '../../assets/everything/fkm.png'
import pic2_3 from '../../assets/everything/cleanair.png'
import pic2_4 from '../../assets/everything/nah.png'

import pic3_1 from '../../assets/selected_page/NAIYC.jpg'
import pic3_2 from '../../assets/everything/in.gif'
import pic3_3 from '../../assets/everything/minikarat.gif'
import pic3_4 from '../../assets/everything/shoot.gif'

import pic4_1 from '../../assets/everything/brand.gif'
import pic4_2 from '../../assets/everything/2018.gif'
import pic4_3 from '../../assets/everything/dilu.png'
import pic4_4 from '../../assets/everything/what.jpg'

import pic5_1 from '../../assets/everything/02.jpg'
import pic5_2 from '../../assets/everything/collage.gif'
import pic5_3 from '../../assets/everything/moneydestroyer.jpg'
import pic5_4 from '../../assets/everything/deedee-01.jpg'

import pic6_1 from '../../assets/everything/youtube.gif'
import pic6_2 from '../../assets/selected_page/fvsf.png'
import pic6_3 from '../../assets/selected_page/BARTER.png'
import pic6_4 from '../../assets/everything/urn.gif'

import pic7_1 from '../../assets/everything/hermess.jpg'
import pic7_2 from '../../assets/everything/object.png'
import pic7_3 from '../../assets/everything/logo-01.png'
import pic7_4 from '../../assets/everything/_MG_0159.JPG'

import pic8_1 from '../../assets/selected_page/outlinecover.jpg'
import pic8_2 from '../../assets/everything/856611_348705808576582_1751212540_o.jpg'
import pic8_3 from '../../assets/everything/IMG_3240.jpg'

import aquarium from '../../assets/aquarium.jpg';

const EverythingItem = ({ name='', picture='' }) => {
  return (
    <div className='Everything-Item'>
      <div id='everything-picture-frame'>
        <img src={picture} width={'100%'} />
      </div>
      <div className='Everything-Item-Name' id='In-Detail'>{name}</div>
    </div>
  )
}

const EverythingItemContainer = ({ items=[], index=0 }) => {
  let classname = 'Everything-Item-Container'
  
  if (index == 0) {
    classname = 'Ugly-Everything-Item-Container'
  }

  return (
    <div className={classname}>
      {items.map(item => <EverythingItem name={item.name} picture={item.picture} />)}
    </div>
  )
}

const Everything = () => {
  const project_groups = [
    [{
      name: 'Art direction for Freckled Market (2021)',
      picture: pic1_0
    }, {
      name: 'Logo design for Olic, Thailand (2020)',
      picture: pic1_1
    }, {
      name: 'Research and strategy for Hoi Sang Aquarium, Thailand (2020)',
      picture: pic1_2
    }, {
      name: 'Business model consultant for Poonsook NGO, Thailand (2020)',
      picture: pic1_3
    }], 
    [{
      name: 'Research and Strategy for SNP HQ (2019)',
      picture: pic1_4
    }, {
      name: 'UX design and research for Taladrod, Thailand (2019)ect5',
      picture: pic2_1
    }, {
      name: 'Branding for Freckled Market (2019)',
      picture: pic2_2
    }, {
      name: 'Branding for Thailand Clean Air Network (NGO), Thailand (2019)',
      picture: pic2_3
    }],
    [{
      name: 'Competitors research for Nahtlos, Switzerland (2019)',
      picture: pic2_4
    }, {
      name: 'Launch my own brand, NAIYC (2018)',
      picture: pic3_1
    }, {
      name: 'Interior design for Mini Karats, Thailand (2018)',
      picture: pic3_2
    }, {
      name: 'Art Direction for Mini Karats, Thailand (2018)',
      picture: pic3_3
    }],
    [{
      name: 'Art Direction for Petals & Pantone, Thailand (2018)',
      picture: pic3_4
    }, {
      name: 'Branding for Petals & Pantone, Thailand (2018)',
      picture: pic4_1
    }, {
      name: 'Illustration I made for Freckled Market (2018)',
      picture: pic4_2
    }, {
      name: 'Research and Ideation (drone and kinetic sculpture) for Dilussion Inc, South Korea (2018)',
      picture: pic4_3
    }],
    [{
      name: 'Illustration for Kult Magazine #22, Singapore (2017)',
      picture: pic4_4
    }, {
      name: 'Self-portrait (2016)',
      picture: pic5_1
    }, {
      name: 'Collage I made for fun (2016)',
      picture: pic5_2
    }, {
      name: 'Illustration for Kult Magazine #18, Singapore (2016)',
      picture: pic5_3
    }],
    [{
      name: 'Branding for DeeDee dessert (2016)',
      picture: pic5_4
    }, {
      name: 'Branding for Mossster, youtuber (2016)',
      picture: pic6_1
    }, {
      name: 'Facts VS Faith, Thesis project (2015 - 2016)',
      picture: pic6_2
    }, {
      name: 'Barter, school final project (2015)',
      picture: pic6_3
    }],
    [{
      name: 'Urn-it, school final project (2015)',
      picture: pic6_4
    }, {
      name: 'Selling stickers under the name Hermess (2015-2016)',
      picture: pic7_1
    }, {
      name: 'Object (2015)',
      picture: pic7_2
    }, {
      name: 'Branding and workshop facilitator for Ground-up Initiative (NGO), Singapore (2015)',
      picture: pic7_3
    }],
    [{
      name: 'Hangbag, changing Interface experiment (2014)',
      picture: pic7_4
    }, {
      name: 'Out-line playbook, school final project (2014)',
      picture: pic8_1
    }, {
      name: 'Administrator the microsite for Bribespot, Thailand (2013)',
      picture: pic8_2
    }, {
      name: 'Drawing (1998)',
      picture: pic8_3
    }]
  ]

  return (
    <div className='Content-Container'>
      <div className='Archive-Container'>
        <div className='Archirve-Description-Container' id='Description'>
          Everything from freelance gigs, killed designs, illustration, art direction, branding, and experiments are here. New to old. <b>Projects’ details are available upon request.</b>
        </div>
        <div className='Everything-Container'>
          {project_groups.map((group, index) => <EverythingItemContainer items={group} index={index}/>)}
        </div>
      </div>
    </div>
  )
}

export default Everything;