import './index.css'

import pic1 from '../../assets/hoisang/coverresize.png'
import pic2 from '../../assets/hoisang/exisitng1.png'
import pic3 from '../../assets/hoisang/existing2.png'
import pic4 from '../../assets/hoisang/competitors.png'
import pic5 from '../../assets/hoisang/example.png'
import pic6 from '../../assets/hoisang/visitors.png'
import pic7 from '../../assets/hoisang/aquarium-05.png'
import pic8 from '../../assets/hoisang/aquarium-03.png'
import pic9 from '../../assets/hoisang/ecosystem.png'
import pic10 from '../../assets/hoisang/execution.png'
import pic11 from '../../assets/hoisang/network.jpg'
import pic12_1 from '../../assets/hoisang/before.png'
import pic12_2 from '../../assets/hoisang/after.png'
import pic13 from '../../assets/hoisang/space.png'
import pic14 from '../../assets/hoisang/credential.png'

import Carousel from '../../default_components/carousel'

const SONGKLA_AQ = () => {
  return (
    <div className='Project-Information-Container'>
      <div id='Overview'>
        <div className='Project-Single-Content-Container'>
          <div className='Project-Image-Container'>
            <img id='Project-Overview-Image1' src={pic1} width={'100%'} />
          </div>
        </div>
        <div id='Project-Topic-And-Detail'>
          <div className='Project-Topic-Container' id='Sub-Topic'>Story</div>
          <div className='Project-Detail-Container' id='Description'>Located in Tinsulanonda Fisheries College, the scandalous Songkhla Lake Aquaculture Research Centre or Hoi Sang 
(conch) aquarium had undergone bribery and corruption since 1994 and had nothing but the exterior of the building 
finished, I was bought in as a researcher and strategist to shake things up and make sure that the aquarium is 
beneficial to the society. This work is done under IDS for the Ministry of Education, Thailand.</div>
        </div>
        <div className='Project-Topic-Container' id='Sub-Topic'>Goal</div>
        <div className='Project-Detail-Container' id='Description'>To initially set a design strategy for the Hoi Sang Aquarium that is beneficial for society.</div>
      </div>


      <div id='Situation-Assessment'>
        <div className='Project-Sector-Name-Container' id='Sub-Topic'>Situation Assessment</div>
        <div className='Project-Topic-Container' id='Sub-Topic'>Existing Design</div>
        <div className='Project-Many-Content-Container'>
          <div className='Project-Image-Container'>
            <img id='Project-Discovery-Image1' src={pic2} width={'100%'} />
          </div>
        </div>
        <div id='Project-Topic-And-Detail'>
          <div className='Project-Detail-Container' id='Description'>Hoi Sang aquarium was solely created to be a landmark of Songkhla city with the hope to attract tourists from the size 
of its seawater tank which was meant to be the biggest in Thailand if it had only finished on time.
Unfortunately, decades-long corruption delays the construction plan, and material quality leads Hoi Sang aquarium 
to nothing but a wasted potential abandoned site. (https://news.thaipbs.or.th/content/295337)</div>
        </div>
        <div className='Project-Single-Content-Container'>
          <div className='Project-Image-Container'>
            <img id='Project-Discovery-Image1' src={pic3} width={'100%'} />
          </div>
        </div>
        <div id='Project-Topic-And-Detail'>
          <div className='Project-Detail-Container' id='Description'>
            At the moment, every essential system of the building is not finished makes the aquarium unable to open 
and operate.
          </div>
        </div>
      </div>


      <div id='Research'>
        <div className='Project-Sector-Name-Container' id='Sub-Topic'>Research</div>
        <div className='Project-Topic-Container' id='Sub-Topic'>Competitor</div>
        <div className='Project-Single-Content-Container'>
          <div className='Project-Image-Container'>
            <img id='Project-Overview-Image1' src={pic4} width={'100%'} />
          </div>
        </div>
        <div id='Project-Topic-And-Detail'>
          <div className='Project-Detail-Container' id='Description'>
            These several aquariums in the area are more attractive and worth visiting than the Hoi Sang aquarium 
and among those aquariums shared common success factors including
          </div>
        </div>
        <div className='Project-Many-Content-Container'>
          <div className='Project-Image-Container'>
            <img id='Project-Research-Image1' src={pic5} width={'100%'} />
          </div>
        </div>
        <div id='Project-Topic-And-Detail'>
          <div className='Project-Detail-Container' id='Description'>
          These aquariums shared three main functions ( education, conservation, and entertainment) in different 
proportions depending on their missions.
          </div>
        </div>
        <div id='Project-Topic-And-Detail'>
          <div className='Project-Topic-Container' id='Sub-Topic'>Visitors</div>
          <div className='Project-Detail-Container' id='Description'>
          There are likely to be five target groups interested in Hoi Sang Aquarium
          </div>
        </div>
        <div className='Project-Many-Content-Container'>
          <div className='Project-Image-Container'>
            <img id='Project-Research-Image2' src={pic6} width={'100%'} />
          </div>
        </div>
        <div id='Project-Topic-And-Detail'>
          <div className='Project-Topic-Container' id='Sub-Topic'>Insight</div>
        </div>
        <div className='Project-Many-Content-Container'>
          <div className='Many-Item-Container'>
            <div className='Project-Image-Container'>
              <img id='Project-Research-Image2' src={pic7} width={'100%'} />
            </div>
          </div>
          <div className='Many-Item-Container'>
            <div className='Project-Detail-Container' id='Description' style={{paddingLeft: '15px'}}>
            Based on interviews, users expectations are corresponding to their visiting purposes and all of them prioritize receiving a good experience from the aquarium.
            </div>
          </div>
        </div>
      </div>


      <div id='Strategy-Hoisang'>
        <div className='Project-Sector-Name-Container' id='Sub-Topic'>Strategy</div>
        <div id='Project-Topic-And-Detail'>
          <div className='Project-Detail-Container' id='Description'>
          To open and operate an aquarium successfully, Hoi Sang aquarium’s role is to perform more than an aquarium. It has to distinguish itself from others using its location and the institute’s fishery expertise. Supporting local produces and distributing the industry’s knowledge to everyone.
          </div>
        </div>
        <div className='Project-Many-Content-Container'>
          <div className='Project-Image-Container'>
            <img id='Project-Design-Image1' src={pic8} width={'100%'} />
          </div>
        </div>
        <div id='Project-Topic-And-Detail'>
          <div className='Project-Topic-Container' id='Sub-Topic'>Stakeholders Map</div>
          <div className='Project-Detail-Container' id='Description'>
          Hoi Sang Aquarium will act as a central hub of the new fishery ecosystem where core users can use the aquarium 
for learning, researching, and training purposes. Direct users will profit from tourism plans that help connect hotels, 
restaurants, and other tourist attractions with the aquarium and indirect users will benefit from the aquarium’s initiatives.
          </div>
        </div>
        <div className='Project-Single-Content-Container'>
          <div className='Project-Image-Container'>
            <img id='Project-Overview-Image1' src={pic9} width={'100%'} />
          </div>
        </div>
        <div id='Project-Topic-And-Detail'>
          <div className='Project-Topic-Container' id='Sub-Topic'>Guidelines</div>
          <div className='Project-Detail-Container' id='Description'>
          I believed other aspects need to be designed to fulfill the criteria. 
          </div>
        </div>
        <div className='Project-Single-Content-Container'>
          <div className='Project-Image-Container'>
            <img id='Project-Overview-Image1' src={pic10} width={'100%'} />
          </div>
        </div>
        <div id='Project-Topic-And-Detail'>
          <div className='Project-Topic-Container' id='Sub-Topic'>Network</div>
        </div>
        <div className='Project-Single-Content-Container'>
          <div className='Project-Image-Container'>
            <img id='Project-Overview-Image1' src={pic11} width={'100%'} />
          </div>
        </div>
        <div id='Project-Topic-And-Detail'>
          <div className='Project-Topic-Container' id='Description'>Networking helps create awareness of hidden tourist attractions and small local businesses. By creating connecting 
trips, local people could potentially earn more than they used to.
          </div>
        </div>
        <div className='Project-Many-Content-Container'>
          <Carousel items={[
              { path: pic12_1 ,name: 'Before' },
              { path: pic12_2, name: 'After' }
            ]} />
        </div>
        <div id='Project-Topic-And-Detail'>
          <div className='Project-Topic-Container' id='Sub-Topic'>Space</div>
          <div className='Project-Topic-Container' id='Description'>
          Hoi Sang Aquarium is divided into three zones including the Main Exhibition hall, food, and others. The food and 
others sections are created to support local’s businesses.
          </div>
        </div>
        <div className='Project-Many-Content-Container'>
          <div className='Project-Image-Container'>
            <img id='Project-Overview-Image1' src={pic13} width={'100%'} />
          </div>
        </div>
        <div id='Project-Topic-And-Detail'>
          <div className='Project-Topic-Container' id='Sub-Topic'>Initiative</div>
        </div>
        <div className='Project-Many-Content-Container'>
          <div className='Many-Item-Container'>
            <div className='Project-Image-Container'>
              <img id='Project-Overview-Image1' src={pic14} width={'100%'} />
            </div>
          </div>
          <div className='Many-Item-Container'>
            <div className='Project-Topic-Container' id='Description' style={{paddingLeft: '15px'}}>
              To support sustainable fishing and add value to<br />
              the local produces, standard credentials need
              to be made and applied on seafood packagings
              and stamped on the restaurants that are supporting
              sustainable fishing.
            </div>
          </div>
        </div>
        <div id='Project-Topic-And-Detail'>
          <div className='Project-Topic-Container' id='Sub-Topic'>Next Phrase</div>
          <div className='Project-Topic-Container' id='Description'>The project has just received the initial fund for engineer and structure testing but because of the COVID-19 situation, 
sight investigation has been delayed to mid-June 2021.
          </div>
        </div>
      </div>
    </div>
  )
}

export default SONGKLA_AQ