import './index.css'
import { A } from 'hookrouter'

import NAIYC from '../../assets/selected_page/NAIYC.jpg'
import SONGKLA_AQ from '../../assets/selected_page/songkla_aquarium.png'
import SNP from '../../assets/selected_page/SNP.png'
import TALARD_ROD from '../../assets/selected_page/taladrod.png'
import FVF from '../../assets/selected_page/fvsf.png'
import BARTER from '../../assets/selected_page/BARTER.png'
import OUTLINE from '../../assets/selected_page/outlinecover.jpg'

const ProjectItem = ({ title='', subtitle='', picture='', description='', url='' }) => {
  let path_url = `/project/${url}`

  return (
    <div className='Project-Item-Container'>
      <A href={path_url}>
        <div className='Project-Title-Container'>
          <div className='Project-Title' id='Side-Sub-Topic'>
            {title}
          </div>
          <div className='Project-Subtitle' id='Description'>{subtitle}</div>
        </div>
        <div className='Project-Picture'>
          <img src={picture} width={'100%'} />
        </div>
      </A>
      <div className='Project-Description' id='Description'>{description}</div>
      <div className='End-Line-Project-Item'></div>
    </div>
  )
}

const Project = () => {
  const projects = [{
    title: 'NAIYC',
    subtitle: '2018 - Present | Concept, design, and operate',
    picture: NAIYC,
    description: 'Launching a mindful fashion brand from hobbies.',
    url: 'naiyc'
  }, {
    title: 'Songkla Aquarium',
    subtitle: '2020 | Research and strategy',
    picture: SONGKLA_AQ,
    description: 'Designing an ecosystem for an abandoned aquarium.',
    url: 'songkla_aq'
  }, {
    title: 'SNP HQ',
    subtitle: '2019 | Research and Strategy',
    picture: SNP,
    description: 'Reimagine a cafe experience of a dying franchise restaurant.',
    url: 'snp'
  }, {
    title: 'Taladrod',
    subtitle: '2019 | UX',
    picture: TALARD_ROD,
    description: 'Designing a better experience for buying and selling used cars online.',
    url: 'talard_rod'
  }, {
    title: 'Facts VS Faith',
    subtitle: '2015 - 16 | Concept, research, and design',
    picture: FVF,
    description: 'Real-life infographics describing health problems many monks are facing resulting from Buddhist practitioners’ common belief in the food offering event.',
    url: 'fvf'
  }, {
    title: 'Barter',
    subtitle: '2015 | Service design',
    picture: BARTER,
    description: 'An application that connects people who need to charge their phone with chargers.',
    url: 'barter'
  }, {
    title: 'Out-line',
    subtitle: '2014 | Concept, research, and design',
    picture: OUTLINE,
    description: "A no-template coloring book aiming to help children discover ways of drawing without borders.",
    url: 'outline'
  }]

  return (
    <div className='Content-Container'>
      <div className='Project-Container'>
        {
          projects.map((project) => {
            return <ProjectItem 
              title={project.title}
              subtitle={project.subtitle}
              picture={project.picture}
              description={project.description}
              url={project.url}
              />
          })
        }
      </div>
    </div>
  )
}

export default Project