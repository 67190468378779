import { useState, useEffect } from 'react'
import './index.css'

const CarouselButton = ({ name, index, action, activedIndex }) => {
  // const id = `carousel-pic-${index}`
  const defaultStyle = {
    "width": "100%",
    "textAlign": "center",
    "font-family": "HelveticaltPro-Bold",
    "border": "0.5px solid #E3E3E3",
    "border-radius": "15px 15px 0px 0px",
    "border-bottom": "0px",
    "padding": "0.6rem 0.5rem",
    "background-color": "#EFEFEF",
  }

  if (activedIndex == index) {
    defaultStyle["background-color"] = "#FFFFFF"
  }

  return (
    <div className='Carousel-Button'>
      <button onClick={() => action(index)} style={defaultStyle}>
        {name}
      </button>
    </div>
  )
}

const Carousel = ({ items=[] }) => {
  const [picIndex, setPicIndex] = useState(0)
  const [activedIndex, setActivedIndex] = useState(0)
  const clickPicture = (picIndex) => {
    setPicIndex(picIndex)
    setActivedIndex(picIndex)
  }

  return (
    <div className='Carousel-Component'>
      <div className='Carousel-Container'>
        <div id='Carousel-Action-Container'>
          {
            items.map((item, index) => {
              return <CarouselButton
                name={item.name}
                index={index}
                action={clickPicture}
                activedIndex={activedIndex}
                />
            })
          }
        </div>
        <div id='Carousel-Board-Container'>
          <img src={items[picIndex].path} width={'100%'} style={{padding: '3px'}} />
        </div>
      </div>
    </div>
  )
}

export default Carousel