import './index.css'
import { A } from 'hookrouter'

const ProjectPageHeaderBar = ({ topic='', description='' }) => {
  let id = 'Topic-Description'

  if (topic == 'Facts VS Faith, 2015~2016') {
    id = 'Ugly-Topic-Description'
  }

  return (
    <div className='ProjectPage-HeaderBar-Container'>
      <div className='Header-Topic-Container'>
        <div className='Home-Link-Container' id='In-Detail'>
          <A href='/project' id="link-back">Back</A>
        </div>
        <div className='Header-Topic' id='Topic'>
          {topic}
        </div>
      </div>
      
      <div className='Header-Content-Container' id={id}>
        {description}
      </div>
    </div>
  )
}

export default ProjectPageHeaderBar