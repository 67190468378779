import React from 'react';
import { useRoutes } from 'hookrouter';
import './App.css';

import NotFound from './components/notfound';
import Everything from './components/everything';

import HomePageLayout from './layouts/homepage_layout';
import ProjectPageLayout from './layouts/projectpage_layout';

import Project from './components/project'

const routes = {
  '/': () => <HomePageLayout><Project /></HomePageLayout>,
  '/project': () => <HomePageLayout><Project /></HomePageLayout>,
  '/project/:id': ({id}) => <ProjectPageLayout page={id}></ProjectPageLayout>,
  '/everything': () => <HomePageLayout><Everything /></HomePageLayout>
}


function App() {
  const routeResult = useRoutes(routes)

  return (
    <div className="App">
      {routeResult || <NotFound />}
    </div>
  );
}

export default App;
