import pic1 from '../../../assets/talardrod/graphic-06.png'
// import pic2 from ''
import pic3 from '../../../assets/talardrod/sketch-01.png'
import pic4 from '../../../assets/talardrod/b01_c.png'
import pic5 from '../../../assets/talardrod/b02_c copy.png'
import pic6 from '../../../assets/talardrod/b03_c.png'
import pic7 from '../../../assets/talardrod/buyer_m.png'

const BuyerContent = () => {
  return (
    <div>
      <div id='Project-Topic-And-Detail'>
        <div className='Project-Topic-Container' id='Sub-Topic'>Interview</div>
        <div className='Project-Detail-Container' id='Description'>I did an in-depth interview to understand what exactly they are looking for and their concerns when
 purchasing used cars.
        </div>
      </div>
      <div className='Project-Many-Content-Container'>
        <div className='Project-Image-Container'>
          <img id='Project-Discovery-Image1' src={pic1} width={'100%'} />
        </div>
      </div>
      <div id='Project-Topic-And-Detail'>
        <div className='Project-Topic-Container' id='Sub-Topic'>Journey map</div>
      </div>
      <div className='Project-Many-Content-Container'>
        <div className='Project-Image-Container'>
          <img id='Project-Discovery-Image1' src={pic1} width={'100%'} />
        </div>
      </div>
      <div id='Project-Topic-And-Detail'>
        <div className='Project-Topic-Container' id='Sub-Topic'>Sketch</div>
      </div>
      <div className='Project-Many-Content-Container'>
        <div className='Project-Image-Container'>
          <img id='Project-Discovery-Image1' src={pic3} width={'100%'} />
        </div>
      </div>
      <div id='Project-Topic-And-Detail'>
        <div className='Project-Topic-Container' id='Sub-Topic'>Design Development</div>
        <div className='Project-Detail-Container' id='Description'>Partial image of some design development and changes made according to users’ reaction and
feedback during testing.
        </div>
      </div>
      <div className='Project-Many-Content-Container'>
        <div className='Project-Image-Container'>
          <img id='Project-Discovery-Image1' src={pic4} width={'100%'} />
        </div>
      </div>
      <div className='Project-Many-Content-Container'>
        <div className='Project-Image-Container'>
          <img id='Project-Discovery-Image1' src={pic5} width={'100%'} />
        </div>
      </div>
      <div className='Project-Many-Content-Container'>
        <div className='Project-Image-Container'>
          <img id='Project-Discovery-Image1' src={pic6} width={'100%'} />
        </div>
      </div>
      <div id='Project-Topic-And-Detail'>
        <div className='Project-Topic-Container' id='Sub-Topic'>Mobile Version</div>
      </div>
      <div className='Project-Many-Content-Container'>
        <div className='Project-Image-Container'>
          <img id='Project-Discovery-Image1' src={pic7} width={'100%'} />
        </div>
      </div>
    </div>
  )
}

export default BuyerContent