import { useEffect } from 'react'
import './index.css'

import HomePageHeaderBar from '../../default_components/homepage_header_bar'
import FooterBar from '../../default_components/footer_bar'

const ScrollToTop = () => {
  window.scrollTo(0,0)
}

const HomePageLayout = ({ children }) => {
  useEffect(() => {
    ScrollToTop()
  })

  return (
    <div className='HomePage-Layout-Container'>
      <HomePageHeaderBar />
      {children}
      <FooterBar />
    </div>
  )
}

export default HomePageLayout