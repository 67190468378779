import { useEffect } from 'react'
import './index.css'

import ProjectPageHeaderBar from '../../default_components/projectpage_header_bar'
import SideBar from '../../default_components/side_bar'
import FooterBar from '../../default_components/footer_bar'

import NAIYC from '../../components/naiyc'
import SONGKLA_AQ from '../../components/songkla_aq'
import TALARD_ROD from '../../components/talard_rod'
import SNP from '../../components/snp'
import FVF from '../../components/fvf'
import BARTER from '../../components/barter'
import OUTLINE from '../../components/outline'

const ScrollToTop = () => {
  window.scrollTo(0, 0)
}

const ProjectPageLayout = ({ page='' }) => {
  useEffect(() => {
    ScrollToTop()
  })

  const projects = [{
    topic: 'Hoi Sang Aquarium, 2020',
    description: 'Designing an ecosystem for an abandoned aquarium.',
    menu: [
      { name: 'Overview', id: 'Overview' },
      { name: 'Situation Assessment', id: 'Situation-Assessment' },
      { name: 'Research', id: 'Research' },
      { name: 'Strategy', id: 'Strategy-Hoisang' }
    ],
    content: <SONGKLA_AQ />,
    url: 'songkla_aq'
  }, {
    topic: 'Taladrod, 2019',
    description: 'Designing a better experience for buying and selling used cars online.',
    menu: [
      { name: 'Overview', id: 'Overview' },
      { name: 'Research', id: 'Research' },
      { name: 'Design', id: 'Design-project2' }
    ],
    content: <TALARD_ROD />,
    url: 'talard_rod'
  }, {
    topic: 'NAIYC, 2018~Present',
    description: 'Launching a mindful fashion brand from hobbies',
    menu: [
      { name: 'Overview', id: 'Overview' },
      { name: 'Brand', id: 'Brand' },
      { name: 'Channel', id: 'Channel' }
    ],
    content: <NAIYC />,
    url: 'naiyc'
  }, {
    topic: 'SNP, 2019',
    description: 'Reimagine a cafe experience of a dying franchise restaurant.',
    menu: [
      { name: 'Overview', id: 'Overview' },
      { name: 'Situation Assessment', id: 'Situation-Assessment' },
      { name: 'Research', id: 'Research' },
      { name: 'Strategy', id: 'Strategy' }
    ],
    content: <SNP />,
    url: 'snp'
  }, {
    topic: 'Facts VS Faith, 2015~2016',
    description: 'Real-life infographics displaying health problems many monks are facing resulting from Buddhist practitioners’ common beliefs in the food offering event.',
    menu: [
      { name: 'Overview', id: 'Overview'},
      { name: 'Problem Found', id: 'Problem-Found'},
      { name: 'Research', id: 'Research'},
      { name: 'Design', id: 'Design-project5'},
      { name: 'Prototype', id: 'Prototype'}
    ],
    content: <FVF />,
    url: 'fvf'
  }, {
    topic: 'Barter, 2015',
    description: "An application that connects people who need to charge their phone with chargers.",
    menu: [
      { name: 'Overview', id: 'Overview'},
      { name: 'Discovery', id: 'Discovery'},
      { name: 'Research', id: 'Research'},
      { name: 'Design', id: 'Design'},
      { name: 'Testing', id: 'Testing'}
    ],
    content: <BARTER />,
    url: 'barter'
  }, {
    topic: 'Out-line, 2014',
    description: "A no-template coloring book aiming to help children discover ways of drawing without borders.",
    menu: [
      { name: 'Overview', id: 'Overview'},
      { name: 'Research', id: 'Research'},
      { name: 'Ideation', id: 'Ideation'},
      { name: 'Design', id: 'Design'},
      { name: 'Testing', id: 'Testing'}
    ],
    content: <OUTLINE />,
    url: 'outline'
  }]

  let page_index = projects.findIndex(project => project.url == page)

  if (page_index == -1) {
    page_index = projects.findIndex(project => project.url == 'naiyc')
  }

  return (
    <div className='ProjectPage-Layout-Container'>
      <ProjectPageHeaderBar topic={projects[page_index].topic} description={projects[page_index].description} />
      <div className='Project-Content-Container'>
        <SideBar menu={projects[page_index].menu} />
        {projects[page_index].content}
        <div className='Project-Footer-Container'>
          <FooterBar />
        </div>
      </div>
    </div>
  )
}

export default ProjectPageLayout