import React from 'react';

const NotFound = () => {
  console.log('render NotFound')

  return (
    <div className='Content-Container'>NotFound Content</div>
  )
}

export default NotFound;