import pic1 from '../../../assets/talardrod/graphic-01.png'
import pic2 from '../../../assets/talardrod/graphic-11.png'
import pic3 from '../../../assets/talardrod/graphic-12.png'
import pic4 from '../../../assets/talardrod/FINANCE-01.png'
import pic5 from '../../../assets/talardrod/FINANCE-02.png'
import pic6 from '../../../assets/talardrod/FINANCE-03.png'
import pic7 from '../../../assets/talardrod/FINANCE-04.png'
import pic8 from '../../../assets/talardrod/finance_m.png'

const FinancialContent = () => {
  return (
    <div>
      <div id='Project-Topic-And-Detail'>
        <div className='Project-Topic-Container' id='Sub-Topic'>Interview</div>
        <div className='Project-Detail-Container' id='Description'>Taladrod’s financial services are created to serve users who purchased cars from Taladrod’s platform
and outside Taladrod’s platform. Users need a seamless financial experience after the users made 
the purchasing decision.
        </div>
      </div>
      <div className='Project-Many-Content-Container'>
        <div className='Project-Image-Container'>
          <img id='Project-Discovery-Image1' src={pic1} width={'100%'} />
        </div>
      </div>
      <div id='Project-Topic-And-Detail'>
        <div className='Project-Topic-Container' id='Sub-Topic'>Journey map</div>
      </div>
      <div className='Project-Many-Content-Container'>
        <div className='Project-Image-Container'>
          <img id='Project-Discovery-Image1' src={pic2} width={'100%'} />
        </div>
      </div>
      <div id='Project-Topic-And-Detail'>
        <div className='Project-Topic-Container' id='Sub-Topic'>New Journey map</div>
        <div className='Project-Detail-Container' id='Description'>To create a better experience, users can now calculate their down payment, taxes, and fees 
by themselves instead of submitting documents upfront.
        </div>
      </div>
      <div className='Project-Many-Content-Container'>
        <div className='Project-Image-Container'>
          <img id='Project-Discovery-Image1' src={pic3} width={'100%'} />
        </div>
      </div>
      <div id='Project-Topic-And-Detail'>
        <div className='Project-Topic-Container' id='Sub-Topic'>Design Development</div>
        <div className='Project-Detail-Container' id='Description'>Partial image of some design development and changes made according to users’ reaction and
feedback during testing.
        </div>
      </div>
      <div className='Project-Many-Content-Container'>
        <div className='Project-Image-Container'>
          <img id='Project-Discovery-Image1' src={pic4} width={'100%'} />
        </div>
      </div>
      <div className='Project-Many-Content-Container'>
        <div className='Project-Image-Container'>
          <img id='Project-Discovery-Image1' src={pic5} width={'100%'} />
        </div>
      </div>
      <div className='Project-Many-Content-Container'>
        <div className='Project-Image-Container'>
          <img id='Project-Discovery-Image1' src={pic6} width={'100%'} />
        </div>
      </div>
      <div id='Project-Topic-And-Detail'>
        <div className='Project-Detail-Container' id='Description'>To create a truly seamless experience, I designed the future route in preparation for users changing 
behavior. At the moment, Thai users are not ready for this step of doing things online 
but with the help of Taladrod’s assistant, this change can take place soon.
        </div>
      </div>
      <div className='Project-Many-Content-Container'>
        <div className='Project-Image-Container'>
          <img id='Project-Discovery-Image1' src={pic7} width={'100%'} />
        </div>
      </div>
      <div id='Project-Topic-And-Detail'>
        <div className='Project-Topic-Container' id='Sub-Topic'>Mobile Version</div>
      </div>
      <div className='Project-Many-Content-Container'>
        <div className='Project-Image-Container'>
          <img id='Project-Discovery-Image1' src={pic8} width={'100%'} />
        </div>
      </div>
    </div>
  )
}

export default FinancialContent