import './index.css'

import pic1 from '../../assets/talardrod/01.JPG'
import pic2 from '../../assets/talardrod/graphic-08.png'
import pic3 from '../../assets/talardrod/2.png'
import pic4 from '../../assets/talardrod/graphic.png'

import pic5 from '../../assets/talardrod/graphic-09.png'
// import pic6 from '../../assets/hoisang/visitors.png'
// import pic7 from '../../assets/hoisang/aquarium-05.png'
// import pic8 from '../../assets/hoisang/aquarium-03.png'
// import pic9 from '../../assets/hoisang/ecosystem.png'
// import pic10 from '../../assets/hoisang/execution.png'
// import pic11 from '../../assets/hoisang/network.jpg'
// // import pic12 from '../../assets/barter/11.png'
// import pic13 from '../../assets/hoisang/space.png'
// import pic14 from '../../assets/hoisang/credential.png'

import CarouselContent from '../../default_components/carousel_content'
import BuyerContent from './buyer_content'
import SellerContent from './seller_content'
import FinancialContent from './financial_content'

const TALARD_ROD = () => {
  return (
    <div className='Project-Information-Container'>
      <div id='Overview'>
        <div className='Project-Single-Content-Container'>
          <div className='Project-Image-Container'>
            <img id='Project-Overview-Image1' src={pic1} width={'100%'} />
            {/* width={'65%'} height={550} */}
          </div>
        </div>
        <div id='Project-Topic-And-Detail'>
          <div className='Project-Topic-Container' id='Sub-Topic'>Story</div>
          <div className='Project-Detail-Container' id='Description'>Taladrod, one of the very first used cars online marketplace in Thailand, wants to revamp its website to attract 
new customers and drive website traffic. My role for this project is UX research and design. 
This project is done under IDS, Thailand (4months timeline). 
Final version: https://www.taladrod.com/w50/home/</div>
        </div>
        <div className='Project-Topic-Container' id='Sub-Topic'>Goal</div>
        <div className='Project-Detail-Container' id='Description'>Attract new customers and boost sales.</div>
      </div>


      <div id='Research'>
        <div className='Project-Sector-Name-Container' id='Sub-Topic'>Research</div>
        <div className='Project-Topic-Container' id='Sub-Topic'>Existing Design</div>
        <div className='Project-Many-Content-Container'>
          <div className='Project-Image-Container'>
            <img id='Project-Discovery-Image1' src={pic2} width={'100%'} />
          </div>
        </div>
        <div id='Project-Topic-And-Detail'>
          <div className='Project-Topic-Container' id='Sub-Topic'>Old Website</div>
        </div>
        <div className='Project-Many-Content-Container'>
          <div className='Many-Item-Container'>
            <div className='Project-Image-Container'>
              <img id='Project-Discovery-Image1' src={pic3} width={'100%'} />
            </div>
          </div>
          <div className='Many-Item-Container'>
            <div className='Project-Detail-Container' id='Description' style={{paddingLeft: '15px'}}>
              Taladrod is one of Thailand very first used car platform wants to revamp their website to expand the newcomer users age 20-40 and boost their website traffic. With more players in the market, Taladrod’s look is perceived as out-of-dated in the newcomers’ eyes. A more user-friendly UX is needed to be applied.
            </div>
          </div>
        </div>
        <div id='Project-Topic-And-Detail'>
          <div className='Project-Topic-Container' id='Description'>Taladrod needs to change its positioning from being hard to get to know cars expert to a smart partnerto attract and 
deliver experiences newcomers need.</div>
        </div>
        <div className='Project-Many-Content-Container'>
          <div className='Project-Image-Container'>
            <img id='Project-Discovery-Image1' src={pic4} width={'100%'} />
          </div>
        </div>
      </div>


      <div id='Design-project2'>
        <div className='Project-Sector-Name-Container' id='Sub-Topic'>Design</div>
        <div className='Project-Topic-Container' id='Sub-Topic'>User Types</div>
        <div className='Project-Many-Content-Container'>
          <CarouselContent items={[
              { component: BuyerContent, name: 'Buyer' },
              { component: SellerContent, name: 'Seller' },
              { component: FinancialContent, name: 'Financial Service' }
            ]} />
        </div>
        <div id='Project-Topic-And-Detail'>
          <div className='Project-Topic-Container' id='Sub-Topic'>Routes</div>
          <div className='Project-Topic-Container' id='Description'>
            Main stages of the process that buyers, sellers, and people who want to use Taladrod’s financial services have to encounter.
          </div>
        </div>
        <div className='Project-Many-Content-Container'>
          <div className='Project-Image-Container'>
            <img id='Project-Discovery-Image1' src={pic5} width={'100%'} />
          </div>
        </div>
        <div id='Project-Topic-And-Detail'>
          <div className='Project-Topic-Container' id='Description'>
          Please kindly check out  https://www.taladrod.com/w50/home/  to see the design in action.
          </div>
        </div>
      </div>
    </div>
  )
}

export default TALARD_ROD