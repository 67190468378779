import './index.css'

import pic1 from '../../assets/fvf/111.png'
import pic2 from '../../assets/fvf/0111.png'
import pic3 from '../../assets/fvf/02_ copy.png'
import pic4 from '../../assets/fvf/event.png'
import pic5 from '../../assets/fvf/graphics-02.png'
import pic6 from '../../assets/fvf/graphics-04.png'
import pic7 from '../../assets/fvf/graphics-03.png'
import pic8 from '../../assets/fvf/graphics-01.png'
import pic9 from '../../assets/fvf/reaction001.png'
import pic10 from '../../assets/fvf/02designtouchpoint.jpg'
import pic11 from '../../assets/fvf/scenarioo.png'
import pic12 from '../../assets/fvf/artdi.jpeg'
import pic13 from '../../assets/fvf/Scan 250.jpg'
import pic14 from '../../assets/fvf/earlysketch.jpg'
import pic15 from '../../assets/fvf/spoon.png'
import pic16 from '../../assets/fvf/tablecloth.png'
import pic17 from '../../assets/fvf/container.png'
import pic18 from '../../assets/fvf/seating.png'
// TODO: mock picture, delete it
import overview_picture from '../../assets/aquarium.jpg'

const FVF = () => {
  return (
    <div className='Project-Information-Container'>
      <div id='Overview'>
        <div className='Project-Single-Content-Container'>
          <div className='Project-Image-Container'>
            <img id='Project-Overview-Image1' src={pic1} width={'100%'} />
          </div>
        </div>
        <div className='Project-Sector-Name-Container' id='Side-Sub-Topic'>Overview</div>
        <div id='Project-Topic-And-Detail'>
          <div className='Project-Topic-Container' id='Sub-Topic'>Story</div>
          <div className='Project-Detail-Container' id='Description'>Fact vs Faith is my thesis project, examine and display the truth behind occurring health problems among monks 
consequentially from food that Thai Buddhist practitioners’ offerings blindly from their deep-rooted faith.
( FYI: monks can’t pick their food, they can only consume whatever people are offering.)
          </div>
        </div>
        <div className='Project-Topic-Container' id='Sub-Topic'>Goal</div>
        <div className='Project-Detail-Container' id='Description'>Raise awareness among Buddhist practitioners regarding the consequences of their actions to motivate change 
for a better monks’ health condition.</div>
      </div>


      <div id='Problem-Found'>
        <div className='Project-Sector-Name-Container' id='Side-Sub-Topic'>Problem Found</div>
        <div id='Project-Topic-And-Detail'>
          <div className='Project-Detail-Container' id='Description'>Monks’ health condition is exacerbated by two main common beliefs sharing among Thai Buddhist practitioners<br />
1.Offering sweet desserts which have ‘gold’ in their names will result in real-life gold (Homophone)
          </div>
        </div>
        <div className='Project-Many-Content-Container'>
          <div className='Project-Image-Container'>
            <img id='Project-ProblemFound-Image1' src={pic2} width={'100%'}/>
          </div>
        </div>
        <div id='Project-Topic-And-Detail'>
          <div className='Project-Detail-Container' id='Description'>
            2. Monks are being used as vehicles to deliver that oily traditional dishes to their dead relatives.
          </div>
        </div>
        <div className='Project-Many-Content-Container'>
          <div className='Project-Image-Container'>
            <img id='Project-ProblemFound-Image2' src={pic3} width={'100%'} />
          </div>
        </div>
        <div className='Project-Detail-Container' id='Description'>
          Unintentionally, with the rising amount of these sweet, greasy food, monks suffer the consequences of being sick 
with chronic diseases such as diabetes, high blood pressure.
        </div>
        <div className='Project-Detail-Container' id='In-Detail'>
          (reference: http://news.ch3thailand.com/local/20997, https://www.thairath.co.th/news/local/1356762, https://www.hfocus.org/content/2016/07/12420)
        </div>
      </div>


      <div id='Research'>
        <div className='Project-Sector-Name-Container' id='Side-Sub-Topic'>Research</div>
        <div id='Project-Topic-And-Detail'>
          <div className='Project-Topic-Container' id='Sub-Topic'>Exploration Phrase</div>
          <div className='Project-Detail-Container' id='Description'>A challenge I faced at the beginning was that the food offerings event is a small everyday event that happens 
everywhere in Thailand and although there is news broadcasting about these issues across every channel, 
the practitioners don’t seem to act upon it.<p />
          </div>
          <div className='Project-Detail-Container' id='Description'>I knew that I had to find a stakeholder as a start that can act as a spokesperson of the issues and at the same time 
hold the actual food offerings event on a bigger scale and than usual and that is when my lecturer introduced me to 
Suan Mokkh, Bangkok.<p />
          </div>
          <div className='Project-Detail-Container' id='Description'>Suan Mokh is a SPIRITUAL FITNESS & EDUTAINMENT CENTER (https://www.suanmokkh.org) which organize
a monthly food offering event with over 50 practitioners participating.
          </div>
        </div>
        <div className='Project-Many-Content-Container'>
          <div className='Project-Image-Container'>
            <img id='Project-Research-Image1' src={pic4} width={'100%'} />
            <div id='Project-Image-Sources' id='In-Detail'>Image sources: https://www.bia.or.th/html_th/index.php/events/2012-03-12-09-31-10/item/1016-2019-oct-07-09-09-15</div>
          </div>
        </div>
        <div className='Project-Topic-Container' id='Sub-Topic'>Food Offering Process at Suan Mokkh</div>
        <div className='Project-Many-Content-Container'>
          <div className='Project-Image-Container'>
            <img id='Project-Research-Image2' src={pic5} width={'100%'} />
          </div>
        </div>
        <div className='Project-Topic-Container' id='Sub-Topic'>Equipments in use</div>
        <div className='Project-Many-Content-Container'>
          <div className='Many-Item-Container'>
            <div className='Project-Image-Container'>
              <img id='Project-Research-Image3' src={pic6} width={'100%'} />
            </div>
          </div>
          <div className='Many-Item-Container'>
            <div className='Project-Detail-Container' id='Description' style={{paddingLeft: '15px'}}>As a result of zen Buddhism, equipment in use at Suan Mokkh
  are plain but functional. There are three main categories of
  objects users are encounter when participating in the event 
  including 3 main tables, cutlery: food carriers + bowls + spoons, 
  and chairs.
            </div>
          </div>
        </div>
        <div id='Project-Topic-And-Detail'>
          <div className='Project-Topic-Container' id='Sub-Topic'>Target Users</div>
          <div className='Project-Detail-Container' id='Description'>Four main groups of users attend the event (from observation + gaining data collecting of Suan Mokkh) that needs to 
be considered when design including</div>
        </div>
        <div className='Project-Many-Content-Container'>
          <div className='Project-Image-Container'>
            <img id='Project-Research-Image3' src={pic7} width={'100%'}/>
          </div>
        </div>
        <div className='Project-Detail-Container' id='Description'>For most of them, it is best to experience the truth with their own eyes and the best way to reveal facts is to do it 
without their expectations.
        </div>
      </div>


      <div id='Design-project5'>
        <div className='Project-Sector-Name-Container' id='Side-Sub-Topic'>Design</div>
        <div id='Project-Topic-And-Detail'>
          <div className='Project-Topic-Container' id='Sub-Topic'>Scoping</div>
          <div className='Project-Detail-Container' id='Description'>My task assignment will focus mainly on designing the experience participants receive once attending the event.</div>
        </div>
        <div className='Project-Many-Content-Container'>
          <div className='Project-Image-Container'>
            <img id='Project-DesignFeedback-Image1' src={pic8} width={'100%'} />
          </div>
        </div>
        <div className='Project-Topic-Container' id='Sub-Topic'>Ideation</div>
        <div className='Project-Many-Content-Container'>
          <div className='Many-Item-Container'>
            <div className='Project-Image-Container'>
              <img id='Project-DesignFeedback-Image2' src={pic9} width={'100%'} />
            </div>
          </div>
          <div className='Many-Item-Container'>
            <div className='Project-Detail-Container' id='Description' style={{paddingLeft: '15px'}}>The design concept is not to blame any of the 
  participants as they are offering food out of the good 
  intention but to create an aha moment reflecting 
  subtlety from their actions using the existing objects 
  as infographics.
            </div>
          </div>
        </div>
        <div className='Project-Topic-Container' id='Sub-Topic'>Planning</div>
        <div className='Project-Many-Content-Container'>
          <div className='Project-Image-Container'>
            <img id='Project-DesignFeedback-Image3' src={pic10} width={'100%'} />
          </div>
        </div>
        <div className='Project-Topic-Container' id='Sub-Topic'>Mapping</div>
        <div className='Project-Many-Content-Container'>
          <div className='Project-Image-Container'>
            <img id='Project-DesignFeedback-Image3' src={pic11} width={'100%'} />
          </div>
        </div>
        <div className='Project-Topic-Container' id='Sub-Topic'>Art Direction</div>
        <div className='Project-Many-Content-Container'>
          <div className='Many-Item-Container'>
            <div className='Project-Image-Container'>
              <img id='Project-DesignFeedback-Image3' src={pic12} width={'100%'} />
            </div>
          </div>
          <div className='Many-Item-Container'>
            <div className='Project-Detail-Container' id='Description' style={{paddingLeft: '15px'}}>To avoid offending any participants, 
  I decided that it is best to revealing fact 
  subtly through styling and art direction 
  that is blending to the place.
            </div>
          </div>
        </div>
        <div className='Project-Topic-Container' id='Sub-Topic'>Sketch</div>
        <div className='Project-Many-Content-Container'>
          <div className='Many-Item-Container'>
            <div className='Project-Image-Container'>
              <img id='Project-DesignFeedback-Image4' src={pic13} width={'100%'} />
            </div>
          </div>
          <div className='Many-Item-Container'>
            <div className='Project-Detail-Container' id='Description' style={{paddingLeft: '15px'}}>I was an experiment with a different way
  of displaying data on the objects
  reminding that eating traditional
  greasy, sweet, oily food wasn’t bad if
  consume in the minimum amount 
  and that wish to be rich or communicate
  with the death might not be true but the
  consequences are real.
            </div>
          </div>
        </div>
        <div className='Project-Many-Content-Container'>
          <div className='Project-Image-Container'>
            <img id='Project-DesignFeedback-Image5' src={pic14} width={'100%'} />
          </div>
        </div>
      </div>


      <div id='Prototype'>
        <div className='Project-Sector-Name-Container' id='Side-Sub-Topic'>Prototype</div>
        <div id='Project-Topic-And-Detail'>
          <div className='Project-Topic-Container' id='Sub-Topic'>Spoon</div>
          <div className='Project-Topic-Container' id='Sub-Topic'>Layers of Information</div>
        </div>
        <div className='Project-Many-Content-Container'>
          <div className='Project-Image-Container'>
            <img id='Project-DesignFeedback-Image5' src={pic15} width={'100%'} />
          </div>
        </div>
        <div id='Project-Topic-And-Detail'>
          <div className='Project-Topic-Container' id='Sub-Topic'>Tablecloth</div>
          <div className='Project-Detail-Container' id='Description'>There are three tablecloths Buddhist practitioners have to encounter when prep, eat the food. Each of them reflecting 
the main core of why food offering event is essential to Buddhism.
          </div>
        </div>
        <div className='Project-Many-Content-Container'>
          <div className='Project-Image-Container'>
            <img id='Project-DesignFeedback-Image5' src={pic16} width={'100%'} />
          </div>
        </div>
        <div className='Project-Topic-Container' id='Sub-Topic'>Containers</div>
        <div className='Project-Many-Content-Container'>
          <div className='Project-Image-Container'>
            <img id='Project-DesignFeedback-Image5' src={pic17} width={'100%'} />
          </div>
        </div>
        <div className='Project-Topic-Container' id='Sub-Topic'>Seating Area</div>
        <div className='Project-Many-Content-Container'>
          <div className='Project-Image-Container'>
            <img id='Project-DesignFeedback-Image5' src={pic18} width={'100%'} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default FVF